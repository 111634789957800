<template>
  <v-card
    class="licensRender"
    elevation="2"
    tile
    dense
    width="600"
  >
    <v-card-text>
      <v-row
        no-gutters
        dense
        align="center"
        class="mx-0"
      >
        <v-icon
          v-show="license.valid"
          large
          color="#46be8a"
        >
          mdi-check-decagram
        </v-icon>
        <v-icon
          v-show="!license.valid"
          large
          color="#f96868"
        >
          mdi-check-decagram
        </v-icon>
        <v-card-title>
          {{ parts0 }}-{{ parts1 }}-{{ parts2 }}-{{ parts3 }}
        </v-card-title>
        <v-icon large>
          mdi-clock-end
        </v-icon>
        <v-card-title primary-title>
          {{ dateExpAsDate.getDate() }}-{{ dateExpAsDate.getMonth() + 1 }}-{{
            dateExpAsDate.getFullYear()
          }}
        </v-card-title>
        <v-spacer />

        <v-btn
          v-show="resellerView && license.valid"
          class="ma-2"
          color="#faa64c"
          @click="setLicenseValid(license.key_string, false)"
        >
          {{ $t('disable') }}

          <v-icon right>
            mdi-lock
          </v-icon>
        </v-btn>
        <v-btn
          v-show="resellerView && !license.valid"
          class="ma-2"
          color="orange"
          @click="setLicenseValid(license.key_string, true)"
        >
          {{ $t('enable') }}
          <v-icon right>
            mdi-lock-open
          </v-icon>
        </v-btn>
      </v-row>
      <v-row
        no-gutters
        dense
        align="center"
        class="mx-0"
      >
        <v-icon large>
          mdi-account
        </v-icon>
        <v-card-title>
          {{ license.nickname }}
        </v-card-title>

        <v-icon
          large
          class="ml-4 mr-0"
        >
          mdi-email-outline
        </v-icon>

        <a
          color="black"
          :href="`mailto:${license.owner_email}`"
        >
          <v-card-title color="black">
            {{ license.owner_email }}
          </v-card-title>
        </a>
      </v-row>
      <v-row
        no-gutters
        dense
        align="center"
        class="mx-0"
      >
        <v-icon
          large
          class="mr-1"
        >
          mdi-key
        </v-icon>
        <v-card-title>
          {{ activations.length }}/{{ license.activations_limit }}
        </v-card-title>
        <v-spacer />
        <v-card-actions>
          <v-btn
            icon
            text
            @click="show = !show"
          >
            <v-icon>
              {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
          {{ $t('activations') }}
        </v-card-actions>
      </v-row>

      <v-card
        v-for="activation in activations"
        v-show="show"
        :key="activation.id"
        tile
        dense
        color="#d9d9d9"
        class="mx-auto mt-1 mb-2"
      >
        <v-row
          no-gutters
          dense
          align="center"
          class="mx-1"
        >
          <v-icon class="mr-2">
            mdi-key
          </v-icon>

          {{ parts0 }}-{{ parts1 }}-{{ parts2 }}-{{ parts3 }}-{{
            activation.license_serial
          }}

          <v-icon class="ml-2 mr-2">
            mdi-account
          </v-icon>
          {{ activation.nickname }}
          <v-spacer />
          <v-btn
            v-if="resellerView"
            right
            class="mt-1 mb-1 mr-1"
            color="#faa64c"
            @click="deleteActivation(license.id, activation.license_serial)"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-row>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
  import LicenseService from '@/services/license.service'

  export default {
    name: 'LicenseRender',
    props: {
      license: { type: Object, required: true },
      resellerView: { type: Boolean },
      show: { type: Boolean, default: false },
    },
    data () {
      return {
        id: this.license.id,
        parts0: this.license.key_string.substring(0, 3),
        parts1: this.license.key_string.substring(3, 6),
        parts2: this.license.key_string.substring(6, 9),
        parts3: this.license.key_string.substring(9, 12),
        activations: [],
        dateExpAsDate: new Date(this.license.expiration_date),
        dateCreatedAsDate: new Date(this.license.date_created),
      }
    },
    mounted () {
      // this.showActivations()
      LicenseService.getActivations(this.license.id).then(
        Response => (this.activations = Response.data.list),
      )
    },
    methods: {
      showActivations () {
        LicenseService.getActivations(this.license.id).then(
          Response => (this.activations = Response.data.list),
        )
      },
      setLicenseValid (licenseKey, valid) {
        LicenseService.setLicenseValid(licenseKey, valid).then(() => {
          this.license.valid = valid
        })
      },
      deleteActivation (licenseID, serialNr) {
        LicenseService.deleteActivation(licenseID, serialNr).then(() =>
          this.showActivations(licenseID),
        )
      },
    },
  }
</script>

<style lang="scss" scoped></style>

<template>
  <v-container
    id="licens"
    fluid
  >
    <v-row class="headline mb-3 ml-1">
      <h3>
        {{ $t('licenser') }}
      </h3>

      <new-license-form
        v-if="permissions.create_license == 1"
        right
        class="ml-4"
      />
    </v-row>
    <div
      v-for="license in createdlicenses"
      :key="license.id"
      :maxActivations="license.activations_limit"
    >
      <license-render
        class="mt-2 mb-1"
        :license="license"
        :reseller-view="true"
      />
    </div>

    <v-card
      v-if="userlicenses.length >= 1"
      class="userData"
      width="auto"
    >
      <v-card-title>
        <h2>{{ $t('ownedLicenses') }}</h2>
      </v-card-title>
    </v-card>

    <div
      v-for="license in userlicenses"
      :key="license.id"
      :maxActivations="license.activations_limit"
    >
      <license-render
        class="mt-2 mb-1"
        :license="license"
        :reseller-view="false"
      />
    </div>
  </v-container>
</template>

<script>
  import UserService from '@/services/user.service'
  import LicenseService from '@/services/license.service'
  import LicenseRender from '../components/LicenseRender.vue'
  import NewLicenseForm from '../components/NewLicenseForm.vue'

  export default {
    name: 'Licensview',
    components: { LicenseRender, NewLicenseForm },
    data () {
      return {
        content: '',
        username: '',
        id: '',
        userlicenses: [],
        createdlicenses: [],
        datafrom: '',
        permissions: '',
      }
    },

    computed: {
      currentUser () {
        return this.$store.state.auth.token
      },
    },
    mounted () {
      if (!this.currentUser) {
        this.$router.push('/login')
      } else {
        UserService.getUserData().then(
          response => {
            this.username = response.data._nickname
            this.id = response.data._id
          },
          error => {
            this.content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          },
        )
        LicenseService.getMyLicenses().then(
          response => {
            this.userlicenses = response.data.list
            this.datafrom = response
          },
          error => {
            this.content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          },
        )
        LicenseService.getAllLicenses().then(response => {
          this.createdlicenses = response.data.list
        })
        LicenseService.getMyPermissions().then(response => {
          this.permissions = response.data
        })
      // replace with call to LicenseService above
      // this.userlicenses = LicenseService.getLicensesStub()
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped></style>

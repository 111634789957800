<template>
  <v-row justify="left">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('newlicense') }}
          <v-icon right>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('newlicense') }} </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="ownerNickName"
                  append-icon="person"
                  name="Owner nickname"
                  type="text"
                  :label="$t('licenseOwnerNickname')"
                  required
                />
                <v-text-field
                  v-model="ownerEmail"
                  :rules="[rules.required, rules.email]"
                  append-icon="email"
                  name="Owner mail"
                  type="text"
                  :label="$t('licenseOwnerEmail')"
                  required
                />
                Duration
                <v-select
                  v-model="durationMonths"
                  class="my-2"
                  :items="items"
                  :label="$t('licenseDuration')"
                  dense
                />
                <v-text-field
                  v-model="activationsLimit"
                  :rules="[rules.required, rules.number]"
                  append-icon="people"
                  name="Activations limit"
                  type="text"
                  :label="$t('activations')"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
          <small>{{ $t('required') }}</small>
        </v-card-text>
        <v-card-actions class="mt-0">
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="
              addNewLicense(
                ownerNickName,
                ownerEmail,
                activationsLimit,
                durationMonths
              )
            "
          >
            {{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import licenseService from '../services/license.service'

  export default {
    data: () => ({
      dialog: false,
      items: ['trial', 3, 6, 9, 12],
      ownerNickName: '',
      ownerEmail: '',
      activationsLimit: '',
      durationMonths: 0,
      errorCode: 0,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        number: value => {
          return !isNaN(value) || 'must be a number.'
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }),
    methods: {
      addNewLicense (ownernick, ownerEmail, activationsLimit, durationMonths) {
        if (durationMonths === 'trial') {
          durationMonths = 1
        }
        licenseService
          .createNewLicense(
            ownernick,
            ownerEmail,
            activationsLimit,
            durationMonths,
          )
          .then(Response => {
            this.errorCode = Response.data
            if (this.errorCode > 0) {
              this.dialog = false
            }
          })
      },
    },
  }
</script>

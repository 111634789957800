/* eslint-disable no-unused-vars */
import axios from 'axios'
import authHeader from './auth-header'
// import API_URL from './apiURL'

// const API_URL = 'https://testapi.spildramadronning.dk/'
import { API_URL } from '../config/apiURL.js'

class LicenseService {
  getPublicContent () {
    return axios.get(API_URL + 'v1/ping')
  }

  getMyLicenses () {
    return axios.get(API_URL + 'v1/license/mine', { headers: authHeader() })
  }

  createNewLicense (ownernick, ownerEmail, activationsLimit, durationMonths) {
    console.log(
      ownernick +
        ' ' +
        ownerEmail +
        ' ' +
        activationsLimit +
        ' ' +
        durationMonths,
    )
    return axios.post(
      API_URL + 'v1/license/new',
      {
        owner_nickname: ownernick,
        owner_email: ownerEmail,
        activations_limit: activationsLimit,
        duration_months: durationMonths,
      },
      { headers: authHeader() },
    )
  }

  getMyPermissions () {
    return axios.get(API_URL + 'v1/user/permissions', { headers: authHeader() })
  }

  getActivations (licenseId) {
    return axios.get(API_URL + 'v1/license/activations/' + licenseId, {
      headers: authHeader(),
    })
  }

  setLicenseValid (licenseKey, valid) {
    console.log(licenseKey + ' ' + valid)
    return axios.post(
      API_URL + 'v1/license/setvalid',
      {
        license_key: licenseKey,
        valid: valid,
      },
      { headers: authHeader() },
    )
  }

  deleteActivation (licenseID, serialNr) {
    return axios.post(
      API_URL + 'v1/license/del_activation',
      {
        license_id: licenseID,
        serial: serialNr,
      },
      { headers: authHeader() },
    )
  }

  getAllLicenses () {
    return axios.get(API_URL + 'v1/license/list', { headers: authHeader() })
  }

  getLicensesStub () {
    var rval = [
      { key_string: '111123123', activations_limit: 10 },
      { key_string: '222123123', activations_limit: 15 },
    ]
    return rval
  }
}

export default new LicenseService()

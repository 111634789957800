var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"left"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('newlicense'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-pencil ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('newlicense'))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"person","name":"Owner nickname","type":"text","label":_vm.$t('licenseOwnerNickname'),"required":""},model:{value:(_vm.ownerNickName),callback:function ($$v) {_vm.ownerNickName=$$v},expression:"ownerNickName"}}),_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.email],"append-icon":"email","name":"Owner mail","type":"text","label":_vm.$t('licenseOwnerEmail'),"required":""},model:{value:(_vm.ownerEmail),callback:function ($$v) {_vm.ownerEmail=$$v},expression:"ownerEmail"}}),_vm._v(" Duration "),_c('v-select',{staticClass:"my-2",attrs:{"items":_vm.items,"label":_vm.$t('licenseDuration'),"dense":""},model:{value:(_vm.durationMonths),callback:function ($$v) {_vm.durationMonths=$$v},expression:"durationMonths"}}),_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.number],"append-icon":"people","name":"Activations limit","type":"text","label":_vm.$t('activations'),"required":""},model:{value:(_vm.activationsLimit),callback:function ($$v) {_vm.activationsLimit=$$v},expression:"activationsLimit"}})],1)],1)],1),_c('small',[_vm._v(_vm._s(_vm.$t('required')))])],1),_c('v-card-actions',{staticClass:"mt-0"},[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.addNewLicense(
              _vm.ownerNickName,
              _vm.ownerEmail,
              _vm.activationsLimit,
              _vm.durationMonths
            )}}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }